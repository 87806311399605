import fetchFromApiServer from 'services/api';

export function fetchHomeData(requestObject) {
  const url = `dashboard/graph-detail`;
  return fetchFromApiServer('GET', url, requestObject);
}

export function fetchFundList(requestObject) {
  const url = `funds/fund-detail-list`;
  return fetchFromApiServer('GET', url, {}, requestObject);
}

export function fetchTableData(requestObject) {
  const url = `dashboard/funds`;
  return fetchFromApiServer('GET', url, {}, requestObject);
}

export function uploadFundDoc(requestObject) {
  const url = `funds/upload`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function uploadDataDoc(requestObject) {
  const url = `funds/data-upload`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function getDocNotification(user_id, group_id) {
  const url = `funds/get-doc-notification?user_id=${user_id}&group_id=${group_id}`;
  return fetchFromApiServer('GET', url);
}

export function updateNotificationStatus(requestObject) {
  const url = `funds/update-notification-status`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function getDocConfig(requestObject) {
  const url = `funds/get-document-config`;
  return fetchFromApiServer('GET', url, {}, requestObject);
}

export function getSpreadSheet(requestObject) {
  const url = `funds/get-spreadsheet`;
  return fetchFromApiServer('GET', url, {}, requestObject);
}

export function postDocConfig(requestObject) {
  const url = `funds/upload-document-config`;
  return fetchFromApiServer('POST', url, requestObject);
}
