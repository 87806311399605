// PdfViewer.js
import { Logo } from 'assets';
import Table from 'components/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getJsonValue } from 'services/utils';
import PdfBenchMarks from './PdfBenchmarks';
import PdfFees from './pdfFees';
import ReactMarkdown from 'react-markdown';
import PdfScoring from './PdfScoring';
import FundCompareTable from './FundCompareTable';
import PdfPortfolios from './PdfPortfolios';
import { getFundCompareFields } from 'modules/home/slice/homeSlice';
import { getfundDetails, updateFundStatusByID } from '../Slice/FundSlice';
import { toast } from 'react-toastify';
import { setFundData } from '../Slice/FundDataSlice';
import TrackRecord from './TrackRecord';
import { marked } from 'marked';

const PdfViewer = ({
  fundKey,
  fundCompareData,
  directCall = 0,
  SelectedOptions = [],
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const portfolioData = useSelector((state) => state.portfolioData);
  const { fundData } = useSelector(({ fundData }) => fundData);
  const matrixData = useSelector((state) => state.scoringMatrix.matrixData);
  console.log('fundData ', fundData);
  const [divisionData, setDivisionData] = useState(null);

  let isBlackBookUrl =
    window.location.href === `${window.location.origin}/black-book`;

  if (directCall === 1)
    isBlackBookUrl =
      window.location.href ===
      `${window.location.origin}/black-book/${fundKey}`;

  const renderMarkdown = (text) => {
    return (
      <ReactMarkdown
        components={{
          h2: ({ node, ...props }) => <h2 className="custom-h2" {...props} />,
          p: ({ node, ...props }) => <span {...props} />,
        }}
        children=""
      >
        {text}
      </ReactMarkdown>
    );
  };

  const convertToHTML = (text) => {
    let modifiedHTML = marked(text);
    modifiedHTML = modifiedHTML.replace(//g, '<li>').replace(/\n/g, '</li>');
    modifiedHTML = modifiedHTML.replace(/<li>([\s\S]+?)<\/li>/g, '<ul>$&</ul>');
    return modifiedHTML;
  };

  const onMain = useCallback(() => {
    navigate('/');
  }, [navigate]);

  function generateTable(entries) {
    if (!entries || entries.length === 0) {
      return null;
    }

    return entries.map((entry, index) => {
      const feeType =
        entry.fee_type !== null && entry.fee_type !== 'null'
          ? entry.fee_type
          : '';
      const feeValue =
        entry.fee_value !== null && entry.fee_value !== 'null'
          ? renderMarkdown(entry.fee_value)
          : '';
      const feeDescription =
        entry.fee_description !== null && entry.fee_description !== 'null'
          ? renderMarkdown(entry.fee_description)
          : '';

      return (
        <p
          className={
            isBlackBookUrl
              ? `text-14-executive`
              : `web-typography-mini-executive`
          }
          key={index}
        >
          {feeType}:{' '}
          <span
            className={isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`}
          >
            {feeValue}. {feeDescription}
          </span>
        </p>
      );
    });
  }

  function generateTableFee(entries) {
    if (!entries || entries?.length === 0) {
      return null;
    }

    return entries?.map((entry, index) => {
      const feeType =
        entry?.fee_type !== null && entry?.fee_type !== 'null'
          ? entry?.fee_type
          : '';
      const feeValue =
        entry?.fee_value !== null && entry?.fee_value !== 'null'
          ? entry?.fee_value
          : '';
      const feeDescription =
        entry?.fee_description !== null && entry?.fee_description !== 'null'
          ? entry?.fee_description
          : '';
      console.log('feeType', renderMarkdown(feeType), feeValue);

      return {
        key: renderMarkdown(feeType),
        value: (
          <>
            {feeValue} – {renderMarkdown(feeDescription)}
          </>
        ),
      };
    });
  }

  // Get value for fees, portfolio-team, fund-assets
  function getValueForKey(entry, key) {
    let value = '';
    if (entry && entry.hasOwnProperty(key)) {
      value = entry[key] !== null && entry[key] !== 'null' ? entry[key] : '';
    }
    return value;
  }

  const fundJson = () => {
    // Iterate through each object in jsonData array
    let fundDetail = [];
    let fundManager = [];
    let fundTerms = [];
    let fundPerformance = [];
    let fundBenchmarks = [];
    let fundFees = [];
    let portfolioTeam = [];
    let fundAssets = [];
    const fundJson =
      fundData?.fund_data_in_json?.['ai-provided-data']?.[0]?.sections;
    console.log('fundJsonfundJson', fundJson);
    // Iterate through each object in jsonData array
    fundJson?.forEach((obj) => {
      if (obj.hasOwnProperty('fund-details')) {
        fundDetail = obj['fund-details'];
      }
      if (obj.hasOwnProperty('fund-manager')) {
        fundManager = obj['fund-manager'];
      }
      if (obj.hasOwnProperty('fund-terms')) {
        fundTerms = obj['fund-terms'];
      }
      if (obj.hasOwnProperty('fund-performance')) {
        fundPerformance = obj['fund-performance'];
      }
      if (obj.hasOwnProperty('fund-benchmarks')) {
        fundBenchmarks = getJsonValue(obj['fund-benchmarks'], 'values');
      }
      if (obj.hasOwnProperty('fund-fees')) {
        fundFees = getJsonValue(obj['fund-fees'], 'values');
      }
      if (obj.hasOwnProperty('portfolio-team')) {
        portfolioTeam = getJsonValue(obj['portfolio-team'], 'values');
      }
      if (obj.hasOwnProperty('fund-assets')) {
        fundAssets = getJsonValue(obj['fund-assets'], 'values');
      }
    });

    const data = {
      fundDetail: fundDetail,
      fundManager,
      fundTerms,
      fundPerformance,
      fundBenchmarks,
      portfolioTeam,
      fundAssets,
      fundFees,
    };

    return setDivisionData(data);
  };

  useEffect(() => {
    fundJson();
  }, [fundData]);

  function keyValue(entries, key) {
    let value = '';
    if (Array.isArray(entries) && typeof key === 'string') {
      entries?.forEach((entry) => {
        if (entry?.hasOwnProperty(key)) {
          value =
            entry?.[key] !== 'null' && entry?.[key] !== null
              ? entry?.[key]
              : '';
        }
      });
    }
    return value;
  }

  const sendUpdateRequest = (status) => {
    dispatch(updateFundStatusByID({ fund_id: fundKey, fund_status: status }))
      .then((res) => {
        if (res?.payload?.data?.status === 200) {
          dispatch(getfundDetails(fundKey)).then((res) => {
            const fundDataRes = res?.payload?.data?.data;
            dispatch(setFundData(fundDataRes));
          });
          toast.success('Status has been successfully updated');
        }
      })
      .catch((err) => {
        console.log('error sending status update = ', err);
      });
  };

  console.log('isBlackBookUrl', isBlackBookUrl);

  return (
    <div
      style={{
        background: 'var(--bgAccent)',
        overflowY: isBlackBookUrl ? 'auto' : 'visible',
        maxHeight: isBlackBookUrl ? '100vh' : 'none',
      }}
    >
      <div className="header-web-wrap">
        <div className="flex-web">
          <div className="logo" onClick={() => onMain()}>
            <img src={Logo} className="mainLogo" alt="main-logo" />
          </div>
          <div className="header-title">
            <p className="web-title fund-name">{fundData?.fund_name}</p>
          </div>
          <div className="header-action">
            {directCall && fundData?.fund_status === 'CIO Approval' && (
              <>
                <button onClick={() => sendUpdateRequest('Committed')}>
                  Approve
                </button>
                <button
                  className="reject-btn"
                  onClick={() => sendUpdateRequest('Rejected')}
                >
                  Reject
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Recommendation */}
      <div className={'fund-wrap-web m-2 mt-3 p-3'}>
        <p className="web-header">Recommendation</p>
      </div>
      <div className="web-content-header">
        <p
          className={isBlackBookUrl ? 'web-content' : 'web-content-mini'}
          dangerouslySetInnerHTML={{
            __html: `${keyValue(divisionData?.fundDetail, 'asset_class')} ${
              keyValue(divisionData?.fundDetail, 'sub_asset_class') && '/'
            } ${keyValue(divisionData?.fundDetail, 'sub_asset_class')}`,
          }}
        />

        <p className="web-content">
          Fund Net Return Expectation:{' '}
          {renderMarkdown(
            keyValue(
              divisionData?.fundPerformance,
              'expected_net_returns_in_moic'
            )
          )}
          {keyValue(
            divisionData?.fundPerformance,
            'expected_net_returns_in_percent'
          ) && '/'}
          {renderMarkdown(
            keyValue(
              divisionData?.fundPerformance,
              'expected_net_returns_in_percent'
            )
          )}
        </p>
        <p className="web-content">
          Fund Relative Risk Evaluation: High / Average / Low
        </p>
      </div>
      <div className={'fund-wrap-content m-2 mt-2 p-3'}>
        <div className="d-flex  justify-content-between align-items-center">
          <div className="recomandation-content">
            <div style={{ gap: '16px' }}>
              <p className="web-typography-recommendation">Recommendation: </p>
              {fundData?.recommendation_amount && (
                <p className="web-span-recommendation">
                  Commit {fundData?.recommendation_amount} to{' '}
                  {fundData?.fund_name}
                </p>
              )}
              <p className="web-span-recommendation">
                {fundData?.recommendation_summary}
              </p>
            </div>
            <div style={{ marginTop: '25px' }}>
              <p className="web-typography-recommendation">
                History with Fund Manager:{' '}
              </p>
              <p className="web-span-recommendation">
                {fundData?.history_with_fundmanager}
              </p>
            </div>
          </div>
          <p style={{}}></p>
        </div>
      </div>
      <PdfPortfolios portfolioData={portfolioData} />

      {/* Executive Summary */}
      <div className={'fund-wrap-web m-2 mt-2 p-3'}>
        <p className="web-header">Executive Summary</p>
      </div>
      {/* <div className="web-content-header">
        <p className="web-content">
          {renderMarkdown(keyValue(divisionData?.fundDetail, 'asset_class'))}{' '}
          {keyValue(divisionData?.fundDetail, 'sub_asset_class') && '/'}
          {renderMarkdown(
            keyValue(divisionData?.fundDetail, 'sub_asset_class')
          )}
        </p>
        <p className="web-content">
          Fund Net Return Expectation:{' '}
          {renderMarkdown(
            keyValue(
              divisionData?.fundPerformance,
              'expected_net_returns_in_moic'
            )
          )}
          {keyValue(
            divisionData?.fundPerformance,
            'expected_net_returns_in_percent'
          ) && '/'}
          {renderMarkdown(
            keyValue(
              divisionData?.fundPerformance,
              'expected_net_returns_in_percent'
            )
          )}
        </p>
        <p className="web-content">
          Fund Relative Risk Evaluation: High / Average / Low
        </p>
      </div> */}
      <div className={'fund-wrap-content m-2 mt-2 p-3'}>
        <div className="d-flex  justify-content-between align-items-center">
          <div className="recomandation-content">
            <div style={{ gap: '16px' }}>
              <p
                className={
                  isBlackBookUrl
                    ? `text-14-executive`
                    : `web-typography-mini-executive`
                }
              >
                Sector Focus:{' '}
                <span
                  className={
                    isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`
                  }
                >
                  {renderMarkdown(
                    keyValue(divisionData?.fundDetail, 'sector_focus')
                  )}
                </span>
              </p>
              <p
                className={
                  isBlackBookUrl
                    ? `text-14-executive`
                    : `web-typography-mini-executive`
                }
              >
                Fund Size:{' '}
                <span
                  className={
                    isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`
                  }
                >
                  {renderMarkdown(keyValue(divisionData?.fundDetail, 'size'))}
                  &nbsp;
                  {renderMarkdown(
                    keyValue(divisionData?.fundDetail, 'max_fund_size')
                  )}
                  max
                </span>
              </p>
              <p
                className={
                  isBlackBookUrl
                    ? `text-14-executive`
                    : `web-typography-mini-executive`
                }
              >
                GP Commitment:{' '}
                <span
                  className={
                    isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`
                  }
                >
                  {renderMarkdown(
                    keyValue(divisionData?.fundTerms, 'gp_commitment')
                  )}
                </span>
              </p>
              <p
                className={
                  isBlackBookUrl
                    ? `text-14-executive`
                    : `web-typography-mini-executive`
                }
              >
                Term:{' '}
                <span
                  className={
                    isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`
                  }
                >
                  {renderMarkdown(
                    keyValue(divisionData?.fundTerms, 'fund_duration')
                  )}
                </span>
              </p>
              {generateTable(divisionData?.fundFees)}
              <p
                className={
                  isBlackBookUrl
                    ? `text-14-executive`
                    : `web-typography-mini-executive`
                }
              >
                Distribution Waterfall:{' '}
                <span
                  className={
                    isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`
                  }
                >
                  {renderMarkdown(
                    keyValue(divisionData?.fundTerms, 'distribution_waterfall')
                  )}
                </span>
              </p>
              <p
                className={
                  isBlackBookUrl
                    ? `text-14-executive`
                    : `web-typography-mini-executive`
                }
              >
                Key Person:{' '}
                <span
                  className={
                    isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`
                  }
                >
                  {divisionData?.portfolioTeam
                    ?.map(
                      (person) =>
                        `${getValueForKey(person, 'first_name')} ${getValueForKey(person, 'last_name')}`
                    )
                    .join(', ')}
                </span>
              </p>
              <p
                className={
                  isBlackBookUrl
                    ? `text-14-executive`
                    : `web-typography-mini-executive`
                }
              >
                Key Person Risk:{' '}
                <span
                  className={
                    isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`
                  }
                >
                  {renderMarkdown(
                    keyValue(divisionData?.fundDetail, 'key_person_risk_clause')
                  )}
                </span>
              </p>
              <p
                className={
                  isBlackBookUrl
                    ? `text-14-executive`
                    : `web-typography-mini-executive`
                }
              >
                Early Termination:{' '}
                <span
                  className={
                    isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`
                  }
                >
                  {renderMarkdown(
                    keyValue(
                      divisionData?.fundDetail,
                      'early_termination_details'
                    )
                  )}
                </span>
              </p>
              <p
                className={
                  isBlackBookUrl
                    ? `text-14-executive`
                    : `web-typography-mini-executive`
                }
              >
                Fundraising Status:{' '}
                <span
                  className={
                    isBlackBookUrl ? 'web-span-mini-14' : `web-span-mini`
                  }
                >
                  {/* {keyValue(
                      divisionData?.fundDetail,
                      'early_termination_details'
                    )} */}
                </span>
              </p>
            </div>
          </div>
          <p style={{}}></p>
        </div>
      </div>
      <div className={'fund-wrap-content m-2 mt-2 p-3'}>
        <div className="d-flex  justify-content-between align-items-center">
          <div className="recomandation-content">
            <PdfBenchMarks
              fundData={fundData?.fund_data_in_json?.['ai-provided-data']?.[0]}
            />
          </div>
        </div>
      </div>
      {/* Scoring Matrix */}
      <div className={'fund-wrap-web m-2 mt-2 p-3'}>
        <p className="web-header-scoring">Scoring Matrix</p>
      </div>
      <div className={'fund-wrap-content m-2 mt-2 p-3'}>
        <div className="d-flex  justify-content-between align-items-center">
          <div className="recomandation-content">
            <PdfScoring fundData={matrixData} />
          </div>
        </div>
      </div>
      {/* Value Creation */}
      <div className={'fund-wrap-web m-2 mt-2 p-3'}>
        <p className="web-header">Value Creation</p>
      </div>

      <div className={'fund-wrap-content m-2 mt-2 p-3'}>
        <div className="d-flex  justify-content-between align-items-center">
          <div className="recomandation-content">
            <div style={{ gap: '16px' }}>
              <p className="web-typography-value">Investment Strategy: </p>
              <p className="web-span">
                {renderMarkdown(
                  keyValue(
                    divisionData?.fundDetail,
                    'investment_strategy_details'
                  )
                )}
              </p>
              <p className="web-typography-value">
                Portfolio Construction Approach:
              </p>
              <p className="web-span">
                {renderMarkdown(
                  keyValue(
                    divisionData?.fundDetail,
                    'portfolio_construction_details'
                  )
                )}
              </p>
            </div>
            <div style={{ marginTop: '25px' }}>
              {/* <p className="web-typography-value">History with San Jose: </p>
              <p className="web-span">
                San Jose first met with Lime Rock Partners in 2017 when Fund VII
                was launched.
              </p> */}
              <p className="web-typography-value">Competitive Advantage:</p>
              <p className="web-span">
                {renderMarkdown(
                  keyValue(
                    divisionData?.fundDetail,
                    'competitive_advantage_details'
                  )
                )}
              </p>
              <p className="web-typography-value">Competitive Landscape:</p>
              <p
                className="web-span"
                dangerouslySetInnerHTML={{
                  __html: convertToHTML(
                    keyValue(
                      divisionData?.fundDetail,
                      'competitive_landscape_summary'
                    )
                  ), // Convert markdown to HTML
                }}
              />
            </div>
          </div>
          <p style={{}}></p>
        </div>
      </div>

      {/* Performance Expectations */}
      <div className={'fund-wrap-web m-2 mt-2 p-3'}>
        <p className="web-header">Performance Expectations</p>
      </div>
      <div className={'fund-wrap-content m-2 mt-2 p-3'}>
        <div className="d-flex  justify-content-between align-items-center">
          <div className="recomandation-content">
            <PdfFees
              carried_interest={
                divisionData?.fundTerms &&
                keyValue(divisionData?.fundTerms, 'carried_interest')
              }
              generateTableFee={generateTableFee(divisionData?.fundFees)}
              size={keyValue(divisionData?.fundDetail, 'size')}
              investment_pacing={keyValue(
                divisionData?.fundTerms,
                'investment_pacing'
              )}
              fund_raise_period={keyValue(
                divisionData?.fundTerms,
                'fund_raise_period'
              )}
              investment_period={keyValue(
                divisionData?.fundTerms,
                'investment_period'
              )}
              investment_term={`${keyValue(divisionData?.fundTerms, 'fund_duration')} - ${keyValue(divisionData?.fundTerms, 'extension')}`}
              net_irr={keyValue(divisionData?.fundPerformance, 'net_irr')}
              expected_net_returns_in_moic={keyValue(
                divisionData?.fundPerformance,
                'expected_net_returns_in_moic'
              )}
              net_roi={keyValue(divisionData?.fundPerformance, 'net_roi')}
            />
          </div>
        </div>
      </div>
      {/* Historical Fund Track Record */}
      <div className={'fund-wrap-web m-2 mt-2 p-3'}>
        <p className="web-header">Historical Fund Track Record</p>
      </div>
      <div className={'fund-wrap-content m-2 mt-2 p-3'}>
        <div className="d-flex  justify-content-between align-items-center">
          <div className="recomandation-content">
            <TrackRecord
              fundData={fundData?.fund_data_in_json?.['ai-provided-data']?.[0]}
              fundkey={fundKey}
            />
          </div>
        </div>
      </div>
      {/* Fund Comparison - Evaluation of terms */}
      <div className={'fund-wrap-web m-2 mt-2 p-3'}>
        <p className="web-header">Fund Comparison - Evaluation of terms</p>
      </div>
      <div className={'fund-wrap-content m-2 mt-2 p-3'}>
        <div className="d-flex  justify-content-between align-items-center">
          <div className="recomandation-content">
            <FundCompareTable
              fundCompareData={fundCompareData}
              fundKey={fundKey}
              SelectedOptions={SelectedOptions}
            />
          </div>
        </div>
      </div>
      {/* <div className={'fund-wrap-content m-2 mt-2 p-3'}>
        <div className="d-flex  justify-content-between align-items-center">
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div> */}
    </div>
  );
};

export default PdfViewer;
